/* hero2.css */

.wpo-hero-section-1 {
    position: relative;
    padding: 200px 0;
    background-color: #EDF2F8; /* Match the left side's background color */
 /* Prevent overflow */
}

.wpo-hero-section-text {
    margin: 0 auto;
    text-align: left;
}

.wpo-hero-title-top span {
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
}

.wpo-hero-title h2 {
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 20px;
}

.wpo-hero-subtitle p {
    font-size: 18px;
    margin-bottom: 30px;
}

.btn.theme-btn {
    padding: 10px 30px;
    background-color: #ffffff; /* White background color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s;
}

.btn.theme-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.hero-image {
    width: 100%;
    height: auto; /* Adjust this value to make the image taller */
    border-radius: 8px;
    object-fit: cover;
}

/* Responsive styles */
@media (max-width: 991px) {
    .wpo-hero-section-1 {
        padding-top: 470px;
        padding-bottom: 410px;
    }

    .wpo-hero-title h2 {
        font-size: 2rem;
    }

    .wpo-hero-subtitle p {
        font-size: 0.9rem;
    }

    .btns .theme-btn {
        padding: 8px 16px;
    }
}

@media (max-width: 767px) {
    .wpo-hero-section-1 {
        padding-top: 440px;
        padding-bottom: 410px;
    }

    .wpo-hero-title h2 {
        font-size: 1.8rem;
    }

    .wpo-hero-subtitle p {
        font-size: 0.8rem;
    }

    .btns .theme-btn {
        padding: 8px 14px;
    }

    .wpo-hero-section-text {
        text-align: center;
    }

    .right-img {
        margin-top: 20px;
        text-align: center;
    }

    .right-img img {
        width: 80%;
        margin: 0 auto;
    }
}