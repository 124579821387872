/* General section padding */
.section-padding {
    padding: 100px 0; /* Adjust the padding as necessary */
}

/* Service section specific styling */
.wpo-service-section {
    position: relative;
    padding: 200px 0;
    background-color: #EDF2F8; /* Match the left side's background color */
    overflow: hidden; /* Prevent overflow */
}

/* Section title styling */
.wpo-section-title {
    text-align: center;
    margin-bottom: 50px;
}

.wpo-section-title h2 {
    font-size: 45px;
    font-weight: 800;
    margin-bottom: 30px;
    color: #333;
}

.wpo-section-title h2 .highlight {
    color: #007bff; /* Button background color */
}

.wpo-service-item {
    margin-bottom: 30px;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.wpo-service-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.wpo-service-icon {
    margin-bottom: 20px;
}

.wpo-service-icon img {
    width: 60px;
    height: 60px;
}

.wpo-service-text h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
}

.wpo-service-text p {
    font-size: 16px;
    color: #666;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .wpo-service-section {
        padding: 200px 0; /* Adjust padding for larger tablets and small laptops */
    }

    .wpo-section-title h2 {
        font-size: 40px; /* Adjust font size for larger tablets and small laptops */
    }
}

@media (max-width: 992px) {
    .wpo-service-section {
        padding: 200px 0; /* Adjust padding for tablets */
    }

    .wpo-section-title h2 {
        padding: 75px 0;
        font-size: 100px; /* Adjust font size for tablets */
    }
}

@media (max-width: 768px) {
    .wpo-service-section {
        padding: 200px 0; /* Adjust padding for larger phones */
    }

    .wpo-section-title h2 {
        padding: 75px 0;
        font-size: 100px; /* Adjust font size for larger phones */
    }
}

@media (max-width: 576px) {
    .wpo-service-section {
        padding: 200px 0; /* Adjust padding for smaller phones */
    }

    .wpo-section-title h2 {
        padding: 50px 0;
        font-size: 100px; /* Adjust font size for smaller phones */
    }
}

/* Extra small phones */
@media (max-width: 480px) {
    .wpo-section-title {
        text-align: center;
        margin-bottom: 50px;
        padding: 200px 0;
    }

    .wpo-service-section {
        padding: 200px 0; /* Adjust padding for extra small phones */
    }

    .wpo-section-title h2 {
        padding: 50px 0;
        font-size: 100px; /* Adjust font size for extra small phones */
    }
}

/* Very small phones */
@media (max-width: 360px) {
    .wpo-service-section {
        padding: 200px 0; /* Adjust padding for very small phones */
    }

    .wpo-section-title h2 {
        padding: 50px 0;
        font-size: 100px; /* Adjust font size for very small phones */
    }
}
