/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');


@font-face {
    font-family: 'Jost';
    src: url('../../fonts/Jost-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../fonts/Jost-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../fonts/Jost-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../fonts/Jost-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Jost';
    src: url('../../fonts/Jost-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

// fonts
$base-font-size: 15;
$base-font: 'Nunito Sans', sans-serif;
$base-font-size: 15;
// $base-font: 'Jost', sans-serif;
$heading-font: 'Jost', sans-serif;
$heading-font-sub: 'Jost', sans-serif; // This can be differentiated if you want to use different weights




// color
$dark-gray: #232f4b;
$body-color: #4f555a;
$white: #fff;
$light: #8188a9;
$black: #000;
$cyan: #848892;

$theme-primary-color: #3757f7;
$theme-primary-color-s2: #071e93;
$body-bg-color: #fff;
$section-bg-color: #f4f8fc;
$text-color: #6e6e6e;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #e1e1e1;
$border-color-s2: #d8e0f1;
