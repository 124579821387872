/* Ensure the header is fixed at the top */
.wpo-site-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: #fff; /* Ensure the background color is set */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for better visibility */
}

/* Add padding to the top of the body to avoid content being hidden behind the fixed header */
body {
  padding-top: 80px; /* Adjust this value according to the height of your header */
}

/* Your existing styles */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

/* Adjusting other necessary styles for your header and menu */
.navigation-holder .navbar-nav li a {
  color: #000;
  padding: 10px 20px;
  text-decoration: none;
}

/* Optional: Adjust mobile menu styling */
.mobail-menu {
  display: none;
}

/* Show the mobile menu for smaller screens */
@media (max-width: 991px) {
  .mobail-menu {
      display: block;
  }

  .navigation-holder {
      display: none;
  }
}
