/* footer.css */
.wpo-site-footer {
    background-color: #00274d;
    color: #ffffff;
    padding: 20px 0;
    position: relative;
    z-index: 9;
}

.wpo-upper-footer {
    padding: 40px 0;
}

.widget-title {
    margin-bottom: 20px;
}

.widget-title h3 {
    color: #ffffff;
}

.about-widget p {
    color: #cccccc;
}

.contact-ft ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.contact-ft ul li {
    display: flex;
    align-items: center;
    color: #cccccc;
}

.contact-ft ul li i {
    margin-right: 10px;
}

.wpo-lower-footer {
    background-color: #001a33;
    padding: 20px 0;
    text-align: center;
    color: #ffffff;
}

.wpo-lower-footer p {
    margin: 0;
}
.footer {
    padding: 20px 0;
    background-color: #f8f9fa; /* Adjust to match your design */
    text-align: center;
}
