/* contactpage.css */

.wpo-contact-pg-section {
    padding: 100px 0;
}

.wpo-contact-form-area {
    padding: 20px;
    text-align: center;
}

.consultation-text {
    margin-bottom: 5px; /* Reduce the bottom margin */
    font-size: 24px;
    font-weight: bold;
    line-height: 1.1; /* Reduce line height for closer spacing */
}

.calendly-inline-widget {
    width: 100%;
    max-width: 100%; /* Ensure the widget takes the full width of the container */
    height: 700px; /* Adjust the height for a larger display */
    border: none;
    margin: 0 auto;
}
