/* General styles */
.menu-close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
}

.ti-close {
    font-size: 24px;
    cursor: pointer;
}

.wpo-site-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff; /* Ensure background color to avoid content showing through */
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

.container-fluid {
    padding-top: 70px; /* Adjust this value if your header height is different */
}

.fixed-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff; /* Ensure this matches your design */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional for better visibility */
    padding: 10px 0; /* Adjust if needed for better spacing */
}

/* Increase logo size on mobile */
@media (max-width: 1000px) {
    .navbar-brand img {
        width: 285px; /* Adjust the width as needed */
        height: auto;
    }
}
