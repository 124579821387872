/* About.css */
.wpo-about-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    text-align: center;
}

.wpo-about-text {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.wpo-about-icon img {
    margin-bottom: 20px;
}

.wpo-about-icon-content h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.wpo-about-icon-content p {
    font-size: 1.2em;
    margin-bottom: 30px;
}

.signature {
    margin-bottom: 40px;
}

.signature img {
    margin-bottom: 10px;
}

.btn.theme-btn {
    padding: 10px 30px;
    font-size: 1em;
    background-color: #007bff; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s;
}

.btn.theme-btn:hover {
    background-color: #0056b3; /* Button hover background color */
}
