/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.mobileMenu {
    display: none;
}

.mobileMenu.show {
    display: block;
    background-color: #fff;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 50vh; /* Full screen height */
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.menu-close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px; /* Same height as the menu items */
    margin-bottom: 20px;
    position: absolute;
    top: 0;
    right: 20px; /* Adjust this value to move closer to the right */
}

.menu-close .clox {
    cursor: pointer;
    font-size: 24px;
    color: #000;
}

.responsivemenu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.responsivemenu li {
    margin-bottom: 10px;
}

.responsivemenu a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
    display: block;
    padding: 10px 0;
    transition: color 0.3s;
}

.responsivemenu a:hover {
    color: #0056b3; /* Change to your primary color */
}

.navbar-toggler {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 24px; /* Adjust height to fit three bars */
}

.navbar-toggler .icon-bar {
    width: 24px; /* Adjust width */
    height: 2px; /* Adjust height */
    background-color: #000;
    margin: 2px 0; /* Adjust margin for spacing between bars */
}

/* Additional Styles for Better Mobile Menu Appearance */
.responsivemenu li {
    border-bottom: 1px solid #eaeaea;
    padding: 10px 0;
}

.responsivemenu li:last-child {
    border-bottom: none;
}

.showmenu {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 1001;
    background-color: #007bff; /* Change to your desired color */
    padding: 12px; /* Adjust padding for size */
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.3s;
}

.showmenu:hover {
    background-color: #0056b3; /* Change to a darker shade on hover */
    transform: scale(1.1); /* Slightly enlarge on hover */
}

.showmenu button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Adjust width */
    height: 40px; /* Adjust height */
    background-color: #fff; /* Inner circle color */
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.showmenu .icon-bar {
    background-color: #fff;
}

/* Hide the showmenu button when the menu is active */
.mobileMenu.show + .showmenu {
    display: none;
}

/* Responsive Adjustments for Mobile Only */
@media (max-width: 767px) {
    .showmenu {
        display: block;
    }

    .wpo-hero-section-1 {
        padding-top: 80px; /* Space for the header */
        padding-bottom: 20px; /* Space for the footer */
    }

    .wpo-hero-title h2 {
        font-size: 28px;
    }

    .wpo-hero-subtitle p {
        font-size: 14px;
    }

    .wpo-hero-section-text {
        padding-left: 15px;
        padding-right: 15px;
    }
}
